<template>
  <div class="h-100">
    <vue-element-loading
      :active="loadingDetail"
      text="دریافت اطلاعات بنر ..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div v-if="bannerId == 0 || bannerId == null">
      <h3 class="emptyTitle">یکی از بنرهای تبلیغاتی را انتخاب کنید</h3>
    </div>
    <div v-else>
      <div id="detailFile" :style="detailFileHeight">
        <audio
          v-if="fileType == 3 && bannerFile != null"
          controls
          style="margin-top: 51%; margin-right: 25%"
        >
          <source :src="bannerFile" />
        </audio>
        <video
          controls
          v-else-if="fileType == 1 && bannerFile != null"
          style="width: 100%; height: 100%"
          :src="bannerFile"
        ></video>
        <img
          v-else-if="fileType == 2 && bannerFile != null"
          :src="bannerFile"
          alt="No Image selected"
          style="max-width: 100%; max-height: 100%; width: 100%; height: 100%"
        />
      </div>
      <div id="detailInfo" :style="detailInfoHeight">
        <h2>
          {{ name }}
          <strong>{{ label }}</strong>
        </h2>
        <p>
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../constants/config";
export default {
  name: "bannerDetailForm",
  components: {},
  props: ["bannerId"],
  data() {
    return {
      heightForm: window.innerHeight,
      detailFileHeight: "",
      detailInfoHeight: `height: ${window.innerHeight - 600}px;min-height: ${
        window.innerHeight - 600
      }px;max-height: ${window.innerHeight - 600}px;`,
      loadingDetail: false,

      name: "",
      label: "",
      description: "",
      bannerImage: "",
      bannerFile: "",
      fileType: null,
    };
  },
  watch: {
    bannerId: function () {
      this.fetchData();
    },
  },
  methods: {
    ...mapActions("banner", ["GetAllClient", "GetById"]),
    getSrc(foolder, src) {
      return `${apiUrlRoot}/Banner/${foolder}/${src}`; //apiUrlRoot + src;
    },
    async fetchData() {
      if (this.bannerId != 0) {
        this.loadingDetail = true;
        let result = await this.GetById({ id: this.bannerId });
        this.loadingDetail = false;
        if (result) {
          this.fileType = result.type;
          this.bannerImage = this.getSrc("Banner", result.bannerImage);
          this.detailFileHeight = ` height: ${window.innerHeight - 500}px;
                                      min-height: ${window.innerHeight - 500}px;
                                      max-height: ${window.innerHeight - 500}px;
                                      background-size: cover;
                                      background-image:url('${
                                        this.bannerImage
                                      }')`;
          this.bannerFile = this.getSrc(
            result.type == 1 ? "Video" : result.type == 2 ? "Image" : "Sound",
            result.fileName
          );
          this.name = result.name;
          this.label = result.label;
          this.description = result.description;
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: "خطا در دریافت اطلاعات بنر <br /><br />",
            type: "error",
          });
        }
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.emptyTitle {
  width: 98%;
  text-align: center;
  float: right;
  margin-top: 5px;
  border-bottom: 1px dotted #000;
  padding-bottom: 3px;
  margin-right: 1%;
}

#detailFile {
  width: 100%;
}
#detailInfo {
  width: 100%;
}

#detailInfo h2 {
  float: right;
  width: 100%;
  text-align: right;
  padding-right: 10px;
  padding-left: 10px;
  font-family: system-ui;
}
#detailInfo strong {
  float: left;
  font-size: 18px;
  color: #838383;
  margin-top: 4px;
  font-family: cursive;
  border-bottom: 1px solid #bbb;
}
#detailInfo p {
  float: right;
  padding: 6px;
  font-size: 16px;
  color: #767676;
  font-family: "IRANSansWeb";
}
</style>
